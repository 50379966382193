import React, { Component } from "react";
import ShoringGallery from './ShoringGallery';
import { Container, Row, Col,Card} from 'react-bootstrap';
class Shoring extends Component{
  constructor(props){
    super(props)

    this.state={
        showGallery: false
    }
}

 showGallery(x){
  window.scrollTo(0,0);
    this.setState(prevState=>({
        showGallery:true,
        slide:x-0
    }))
    

}
HideGallery(){
    
    this.setState(prevState=>({
        showGallery:false
    }))
}
  render(){
  return (
    
       this.state.showGallery?
    <div >
    <ShoringGallery showSlide={this.state.slide}/>
    <button type="button" className="closeButton" aria-label="Close" onClick={()=>this.HideGallery()}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>:
    <div>
      <div className="banner">
        
          
          <h1 className="banner-text">Shoring</h1>
        
      </div>
    
    <Container>
    
  <Row className="justify-content-md-center">
  <Col sm={6} style={{padding:"15px"}}>
  <Card className="card shadow-none">
              <Card.Body>
              <div className="greyBox">
          <h2 className="iconText">Over <span style={{color:'rgb(0, 195, 255)'}}>100</span> Shoring Projects Completed</h2>
          
          </div>
                <Card.Title></Card.Title>
                  <Card.Text>
                  The Stadcon Group utilizes over 20 years of construction experience to provide complete design-build shoring solutions for clients across all of Southern Ontario. Our team has the knowledge and expertise needed for every phase of your custom shoring project.
                  
                  </Card.Text>
                  {/* <img variant="top" alt="logo" className="shoringFirstImage" src={require('./Assets/portfolio (6).jpg')}/> */}
                </Card.Body>
            </Card>
      </Col>
      <Col sm={6} style={{padding:"15px"}}>
  <Card className="card shadow-none">
  <br/>
              <Card.Body  className="blueBox" style={{backgroundColor:'rgb(0, 195, 255)'}}>
              
          <h2 className="iconText" style={{color:'white'}}><span>The Benefits of chosing Stadcon</span></h2>
          
          
                <Card.Title></Card.Title>
                  <Card.Text style={{color:'white'}}>
                  We handle every single aspect of the job from permits to haulage. Our crew is highly experienced and reliable. We have the best equipment for any job, no matter the size.
                  
                  
                  </Card.Text>
                  {/* <img variant="top" alt="logo" className="shoringFirstImage" src={require('./Assets/portfolio (6).jpg')}/> */}
                </Card.Body>
            </Card>
      </Col>
    
  </Row>
  <div className="greyBox">
        <Row>
          <Col sm={12}>
          <br/>
          <h2 className="iconText">Get the Job Done <span style={{color:'rgb(0, 195, 255)'}}>on Time</span> and <span style={{color:'rgb(0, 195, 255)'}}>on Budget</span></h2>
          <br/>
          </Col>
          </Row>
          </div>
  <Row>
          <Col sm={6}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(1)} src={require('./Assets/portfolio (1).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
          
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(2)} src={require('./Assets/portfolio (12).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
          
        </Row>
        <Row>
          <Col sm>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(3)} src={require('./Assets/portfolio (18).jpg')}/>
              </Card.Body>
            </Card></Col>
          <Col sm>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(4)} src={require('./Assets/portfolio (19).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
          
        </Row>
        
        <Row>
        <Col sm={6}>
        <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(5)} src={require('./Assets/portfolio (20).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
        <Col sm={6}>
        <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(6)} src={require('./Assets/portfolio (22).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
          </Row>
  </Container>

</div>
  );
  }
}

export default Shoring;