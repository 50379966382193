import React from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap';
function FunctionClick(){
    function clickHandler(){
        console.log('buttonClicked');
    }
    return(
        <div>
            <div className="banner">
        <div className="banner-text">
          <h3>About</h3>
        </div>
      </div>
            <br/>

      <Container>
      <div className="greyBox">
        <Row>
          <Col sm={12}>
          <br/>
          <h3 className="iconText">We Provide Services across the <span style={{color:'rgb(0, 195, 255)'}}>GTA</span> and <span style={{color:'rgb(0, 195, 255)'}}>Southern Ontario</span></h3>
          <br/>
          </Col>
          </Row>
          </div>
          <br/>
          <Row>
          
          <Col sm={12} style={{ paddingLeft: '60px',paddingRight: '60px'}}>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1-z-A6WT4g8OUV71LCutkFve8JDAyRrob" width="100%" height="480"></iframe> 
          
          </Col>
        </Row>



       
        <Row className="justify-content-md-center">
  
      <Col sm={12} style={{padding:"15px"}}>
  <Card className="card shadow-none">
  <br/>
              <Card.Body  className="blueBox" style={{backgroundColor:'rgb(0, 195, 255)'}}>
              
          <h3 className="iconText" style={{color:'white'}}><span>Stadcon, Committed to Superior Quality</span></h3>
          
          
                <Card.Title></Card.Title>
                  <Card.Text style={{color:'white'}}>
                  
                  
                  
                  </Card.Text>
                  {/* <img variant="top" alt="logo" className="shoringFirstImage" src={require('./Assets/portfolio (6).jpg')}/> */}
                </Card.Body>
            </Card>
      </Col>
    
  </Row>
        </Container>
          
          
          
         
          
          
      
    
        </div>
    )
}
export default FunctionClick