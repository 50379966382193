import React, { Component } from "react";
import FoundationGallery from './FoundationGallery';
import { Container, Row, Col,Card} from 'react-bootstrap';
class DeepFoundation extends Component{
  constructor(props){
    super(props)

    this.state={
        showGallery: false
    }
}

 showGallery(x){
  window.scrollTo(0,0);
    this.setState(prevState=>({
        showGallery:true,
        slide:x-0
    }))
    

}
HideGallery(){
    
    this.setState(prevState=>({
        showGallery:false
    }))
}
  render(){
  return (
    

    this.state.showGallery?
    <div class="blackBg">
    <FoundationGallery showSlide={this.state.slide}/>
    <button type="button" className="closeButton" aria-label="Close" onClick={()=>this.HideGallery()}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>:
    <div >
      <div className="bannerDeep">
        <div className="banner-text">
          <br/>
          <h1>Deep Foundation</h1>
        </div>
      </div>
      <br/>
    <Container>
    <Row>
      <Col sm>
    <div className="greyBox">
    <br/>
          <h2 className="iconText">The <span style={{color:'rgb(0, 195, 255)'}}>Best</span> Equipment for <span style={{color:'rgb(0, 195, 255)'}}>Serious Jobs</span></h2>
          <br/>
    </div>
    </Col>
    </Row>
  <Row>
    <Col sm style={{padding:"15px"}}>
  <Card className="card shadow-none">
              <Card.Body>
              <div className="greyBox">
          <h2 className="iconText">Deep Foundation</h2>
          </div>
               
                  <Card.Text >
                  We specialize in creating mobile tower foundations. We use the best equipment for deep foundation jobs as they are often large projects that require excavation.
                  </Card.Text>
                  {/* <img variant="top" alt="logo" className="shoringFirstImage" src={require('./Assets/portfolio (6).jpg')}/> */}
                  
                </Card.Body>
            </Card>
      </Col>

      <Col sm style={{padding:"15px"}}>
  <Card className="card shadow-none">
              <Card.Body>
              <div className="blueBox">
          <h2 className="iconText" style={{color:'white'}}>Caisson Drilling</h2>
          
                
                  <Card.Text style={{color:'white'}}>
                  Caisson Drilling is a common technique we use to provide a secure foundation; Our drills range in various diameter sizes depending on the size of the project.</Card.Text>
                  <br/>
                  </div>
                </Card.Body>
            </Card>
      </Col>

      </Row>
      <Row>
    <Col sm>
    <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className=" portfolioImage clickableImage" onClick={()=>this.showGallery(2)} src={require('./Assets/portfolio (16).jpg')}/>
              </Card.Body>
            </Card>
      </Col>
      
    <Col sm>
    <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(3)} src={require('./Assets/portfolio (17).jpg')}/>
              </Card.Body>
            </Card>
    </Col>
  </Row>
  <Row>
          <Col sm={4}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(4)} src={require('./Assets/df (1).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(5)} src={require('./Assets/df (2).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(6)} src={require('./Assets/df (3).jpg')}/>
              </Card.Body>
            </Card>
          </Col>
  </Row>
  <Row>
          <Col sm={4}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(7)} src={require('./Assets/df (4).jpg')}/>
              </Card.Body>
            </Card></Col>
          <Col sm={4}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage " onClick={()=>this.showGallery(8)} src={require('./Assets/df (5).jpg')}/>
              </Card.Body>
            </Card></Col>
          <Col sm={4}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
              <Card.Img variant="top" alt="logo" className="portfolioImage clickableImage" onClick={()=>this.showGallery(9)} src={require('./Assets/df (6).jpg')}/>
              </Card.Body>
            </Card></Col>
  </Row>
</Container>
</div>
  );
}
}

export default DeepFoundation;