import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
const FoundationGallery = (props) => {
  return (
    <div>

      <MDBCarousel
      activeItem={props.showSlide}
      length={9}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <div>
            <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (2).jpg')}
            />
            </div>
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation </h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (16).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        
        <MDBCarouselItem itemId="3">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (17).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="4">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (1).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="5">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (2).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="6">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (3).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="7">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (4).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="8">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (5).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="9">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/df (6).jpg')}
              
            />
          <MDBMask />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Deep Foundation</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

      </MDBCarouselInner>
    </MDBCarousel>
    <br/>
    
    
</div>
  );
}

export default FoundationGallery;