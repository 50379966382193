import React, { Component } from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask} from
"mdbreact";
import { Container, Row, Col, Card, Nav} from 'react-bootstrap';
import ShoringGallery from './ShoringGallery';
class Home extends Component {

  constructor(props){
    super(props)

    this.state={
        showGallery: false
    }
}

 showGallery(x){
  window.scrollTo(0,0);
    this.setState(prevState=>({
        showGallery:true,
        slide:x-0
    }))
    

}
HideGallery(){
    
    this.setState(prevState=>({
        showGallery:false
    }))
}

  render(){
  return (
    this.state.showGallery?
    <div >
      
    <ShoringGallery  showSlide={this.state.slide}/>
    <button type="button" className="closeButton" aria-label="Close" onClick={()=>this.HideGallery()}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>:
            <div>
            <MDBCarousel
            activeItem={1}
            length={2}
            showControls={true}
            showIndicators={true}
            className="z-depth-1"
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
              <a href="/shoring">
                <MDBView>
                  
                  <img
                    className="d-block w-100 carouselImage1"
                  />
                  
                <MDBMask overlay="black-light" />
                </MDBView>
                <MDBCarouselCaption className="MDBCarouselCaption">
                
                
                <h1 className="titleJumbo">STADCON</h1>
                
                
                  <h3>Committed to Superior Quality</h3> 
                </MDBCarouselCaption>
                <MDBCarouselCaption>
                 <p >Shoring</p>
                </MDBCarouselCaption>
                </a>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <a href="/deep-foundation">
                <MDBView>
                  <img
                    className="d-block w-100 carouselImage2"
                    
                  />
                <MDBMask overlay="black-light" />
                </MDBView>
                <MDBCarouselCaption className="MDBCarouselCaption">
                <h1 className="titleJumbo">STADCON</h1>
                  <h3>Committed to Superior Quality</h3> 
                </MDBCarouselCaption>
                <MDBCarouselCaption>
                 <p>Deep Foundation</p>
                </MDBCarouselCaption>
                </a>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
<div className="greyBox">
<br/>
<Container>
        <Row>
          <Col sm={4}>
          <i className='fas fa-hammer icons'></i><br/><br/>
          <h3 className='iconText'>Effecient</h3>
          </Col>
          <Col sm={4}>
          <i className='fas fa-ruler-combined icons'></i><br/><br/>
          <h3 className='iconText'>Detail Oriented</h3>
          </Col>
          <Col sm={4}>
          <i className='fas fa-hard-hat icons'></i><br/><br/>
          <h3 className='iconText'>Reliable</h3>
          </Col>
          </Row>
          </Container>
          <br/>
</div>

          <Container>
        <Row>
          <Col sm={6}>
            
            <div className="Desc">
            <h3 className="WhyChoose">Why Choose Stadcon?</h3>
            <p>Stadcon has grown to become one of Toronto’s leading contractors specializing in <span>shoring</span> and deep <span>foundation</span>. We provide a wide range of construction designs and building services. Our team provides engineering and construction services with <span>over 20 years</span> of experience in shoring, construction drilling and heavy civil projects.
            </p>
          <p>
          We are capable of drilling in all types of soil conditions, as we opperate in major cities around the<span> GTA</span> and all of <span>southern Ontario</span>. Our team has worked in <span>Toronto, Mississauga, Oakville, Burlington, Caledon, Vaughan, Markham, Scarborough</span> and many more. We provide full turnkey service for your project.
          </p>
          </div>
          </Col>

          <Col sm={6}>
          <Card className="card shadow-none"style={{ height: '25rem'}}>
        <Card.Body>
          <div className="portfolioContainer clickableImage">
              <Card.Img variant="top" alt="logo" className="portfolioImage " onClick={()=>this.showGallery(1)} src={require('./Assets/portfolio (1).jpg')}/>
              <h3 className="viewPortfolio">view our portfolio</h3>
              </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <div className="introBox">
        <Row>
          <Col sm={12}>
          <br/>
          <h1 className="iconText">Outstanding Equipment for Any Project</h1>
          <br/>
          </Col>
          </Row>
          </div>
          <Row>
          <Col sm={4}>
          <img alt="logo" className="portfolioImage" src={require('./Assets/portfolio (13).jpg')}/>
          </Col>
          <Col sm={4}>
          <img alt="logo" className="portfolioImage" src={require('./Assets/portfolio (16).jpg')}/>
          </Col>
          <Col sm={4}>
          <img alt="logo" className="portfolioImage" src={require('./Assets/portfolio (17).jpg')}/>
          </Col>
        </Row> */}
          <br/>
          
      </Container>
      
      </div>

    
  );
}
}
export default Home;