import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import  Home  from './components/Home';
import  NavBar  from './components/NavBar';
import  Footer  from './components/Footer';
import  Shoring  from './components/Shoring';
import  DeepFoundation  from './components/DeepFoundation';
import About from './components/About';
import {Helmet} from "react-helmet";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.css';
// import { render } from '@testing-library/react';

class App extends Component {
  
  render() {
  return (
    <div>
      <Helmet>
    <meta charSet="utf-8" />
    <title>Stadcon Shoring Contractors</title>
    <meta charset="UTF-8"></meta>
    <meta name="description" content="The Stadcon group is prepared for any commercial or residential project"></meta>
    <meta name="keywords" content="Shoring, Shoring contractors, Shoring contractors Toronto, Mississauga shoring, caisson drilling, helical piles, ontario shoring, gta shoring"></meta>

    <meta name="author" content="Max Shapovalov"></meta>
    
</Helmet>
    <Router>
      
    <div className="App">
           
      <NavBar/>
      <Route path='/' exact component={Home}/>
      <Route path='/about' component={About}/>
      <Route path='/shoring' component={Shoring}/>
      <Route path='/deep-foundation' component={DeepFoundation}/>
      <Footer/>
    </div>
    </Router>
    </div>
  );
}
}
// const Home = ()=>(
//   <div>
//     <h1><home/></h1>
//   </div>
// );
export default App;
