import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
class NavBar extends Component{
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }
    
    render(){
    return(
      <div ref={this.wrapper}>
    <Navbar collapseOnSelect fixed="top" expand="lg" bg="dark" variant="dark" className="navbar">
  <Navbar.Brand href="/"><img alt="logo" className="logo" src={require('./Assets/stadconlogo.png')}/></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
    <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link onClick={()=>window.scrollTo(300,document.body.scrollHeight)}>Contact</Nav.Link>
      <Nav.Link href="/about">About</Nav.Link>
      <NavDropdown title="Services" id="collapsable-nav-dropdown" className="dropDown">
        <NavDropdown.Item href="/shoring">Shoring</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/deep-foundation">Deep Foundation</NavDropdown.Item>
        
      </NavDropdown>
    </Nav>
    <Nav>
      <Nav.Link href="mailto:webmaster@example.com">lucjan@stadcongroup.com</Nav.Link>
      <Nav.Link eventKey={2} href="/">
      416-891-7555
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
    </div>
    )
    }
}
export default NavBar