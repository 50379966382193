import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const Footer = () => {
  return (
    <MDBFooter color="elegant-color" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
        <MDBCol md="2">
          
          </MDBCol>
          <MDBCol md="3">
            
            <ul>
            <h5 className="title">Contact Us</h5>
              <li className="list-unstyled">
                <a href="#!">Email:lucjan@stadcongroup.com</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Phone:416-891-7555</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Instagram</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Facebook</a>
              </li>
            </ul>
          </MDBCol>
          
          <MDBCol md="2">
            <div className="logoHolder">
          <img alt="logo" src={require('./Assets/provider-01.png')} className="providerLogo"/>
          </div>
          </MDBCol>
          <MDBCol md="2">
          <div className="logoHolder">
          <img alt="logo" src={require('./Assets/provider-02.png')} className="providerLogo"/>
          </div>
          </MDBCol>
          <MDBCol md="3">
          <div className="logoHolder">
          <img alt="logo" src={require('./Assets/provider-03.png')} className="rogersLogo"/>
          </div>
          </MDBCol>
          
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
           Developed by Max Shapovalov
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;