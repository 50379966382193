import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask} from
"mdbreact";
const ShoringGallery = (props) => {
  return (
    <div class="blackBg">

      <MDBCarousel
      activeItem={props.showSlide}
      length={6}
      showControls={true}
      showIndicators={true}
      className="z-depth-1 shoringCarousel"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <div>
            <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (1).jpg')}
            />
            </div>
          <MDBMask  />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Shoring </h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (12).jpg')}
              
            />
          <MDBMask  />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Shoring</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        
        <MDBCarouselItem itemId="3">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (18).jpg')}
              
            />
          <MDBMask  />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Shoring</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="4">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (21).jpg')}
              
            />
          <MDBMask  />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Shoring</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="5">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (20).jpg')}
              
            />
          <MDBMask  />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Shoring</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId="6">
          <MDBView>
          <img
              className="d-block w-100 galleryImage"
              src={require('./Assets/portfolio (22).jpg')}
              
            />
          <MDBMask  />
          </MDBView>
          
          <MDBCarouselCaption>
           <h3>Shoring</h3>
          </MDBCarouselCaption>
        </MDBCarouselItem>

      </MDBCarouselInner>
    </MDBCarousel>
    <br/>
    
    
</div>
  );
}

export default ShoringGallery;